@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.social-media-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1d428a;
    padding: 20px;
}

.username-and-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
}

.username {
    font-size: 44px;
    color: white;
    font-family: 'Bebas Neue', sans-serif;
    margin-bottom: 10px;
}

.icons {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.icons a {
    color: white;
    font-size: 36px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.icons a:hover {
    color: #00bfff;
    transform: scale(1.2);
}

.images-row {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 1300px;
    margin-bottom: 20px;
    justify-content: center;
}

.images-row a {
    transition: transform 0.3s ease;
}

.images-row a:hover {
    transform: scale(1.05);
}

.images-row img {
    width: 100%;
    max-width: 600px;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding-bottom: 25%; /* Aspect ratio adjusted */
    height: 0;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.section-title-yt {
    font-size: 46px;
    color: white;
    text-align: center;
    margin: 10px 0;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 500;
}

@media (max-width: 768px) {
    .username {
        font-size: 32px;
    }

    .icons a {
        font-size: 28px;
    }

    .images-row {
        flex-direction: column;
        align-items: center;
    }

    .section-title-yt {
        font-size: 28px;
    }

    .video-container {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .username {
        font-size: 24px;
    }

    .icons a {
        font-size: 20px;
    }

    .images-row {
        flex-direction: column;
        align-items: center;
    }

    .images-row img {
        max-width: 100%;
        height: auto;
    }

    .section-title-yt {
        font-size: 24px;
    }
}
