.primary-sponsors-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 60px; /* Incrementado para más espacio entre los patrocinadores */
  padding: 10px;
  margin: 20px;
  justify-content: center;
}

.primary-sponsor-logo {
  width: 160px;
  height: 160px;
  object-fit: contain; /* Asegura que la imagen se ajuste sin distorsionarse */
  flex-shrink: 0;
}

/* Responsividad para tablets */
@media (max-width: 1024px) and (min-width: 768px) {
  .primary-sponsors-container {
    flex-wrap: wrap;
    gap: 30px; /* Ajusta el espacio entre los patrocinadores */
  }

  .primary-sponsor-logo {
    width: 140px;  /* Reduce aún más el tamaño en tablets */
    height: 140px;
  }
}

/* Responsividad para móviles */
@media (max-width: 768px) {
  .primary-sponsors-container {
    flex-wrap: wrap;
    gap: 20px; /* Ajusta el espacio entre los patrocinadores */
  }

  .primary-sponsor-logo {
    width: 150px;
    height: 150px;
  }
}

/* Responsividad para pantallas muy pequeñas */
@media (max-width: 480px) {
  .primary-sponsors-container {
    align-items: center;
    gap: 10px; /* Ajusta el espacio entre los patrocinadores */
  }

  .primary-sponsor-logo {
    width: 120px;
    height: 120px;
  }
}
