/* Styles for Escuela Images Gallery */
.escuela-images-gallery-container {
  background-image: url('../../assets/fondoplantilla.jpg');
  background-size: cover; /* Asegura que la imagen cubra toda el área */
  background-position: center center; /* Centra la imagen tanto horizontal como verticalmente */
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px;
  margin: 0 auto; /* Ajuste para centrar y evitar márgenes innecesarios */
  width: 100%;
  min-height: 100vh; /* Ajuste para asegurar que cubra la altura completa de la pantalla */
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .escuela-images-gallery-container {
    background-attachment: scroll; /* Elimina el efecto fixed en móviles para evitar problemas de corte */
    background-size: cover; /* Asegura que la imagen cubra toda el área */
    background-position: top center; /* Ajusta la posición para que se vea mejor en pantallas pequeñas */
    min-height: 100vh; /* Asegura que cubra la altura completa de la pantalla en móviles */
  }
}
.escuela-images-titulo {
  text-align: center;
  margin-bottom: 20px;
  color: #ffffff;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 36px;
}
.escuela-images-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.escuela-images-gallery-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(85, 87, 92, 0);
  backdrop-filter: blur(8px);
  z-index: -1;
  border-radius: 10px;
}

/* General Styles for Fade Section */
.fade {
  position: relative;
  transition: background-image 1s ease-in-out;
  height: 600px !important; /* Ajusta la altura general */
}

.fade::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Ajusta la opacidad y el color aquí */
  z-index: 1;
}

@media (max-width: 768px) {
  .fade {
    height: 780px !important; /* Reduce la altura para móviles */
  }
}

/* Adjustments for Escuelas Content */
.escuelas-content {
  position: relative;
  z-index: 2;
  background: rgba(223, 223, 238, 0.7);
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; /* Centra el contenido */
  height: 100%;
}

.escuelas-content h2 {
  font-size: 50px;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
  font-family: 'Bebas Neue', sans-serif !important;
}

.escuelas-content p {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 768px) {
  .escuelas-content h2 {
    font-size: 36px; /* Reduce el tamaño del título en móviles */
  }

  .escuelas-content p {
    font-size: 16px; /* Reduce el tamaño del texto en móviles */
  }
}

/* Adjustments for Button */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.escuela-images-back-button {
  background-color: #3660e0;
  color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 10px 0;
  width: 30%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.image-button {
  background-color: #3660e0;
  color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 10px 0;
  width: 50%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.image-button:hover {
  background-color: #f39c12;
}

@media (max-width: 768px) {
  .image-button {
    width: 90%; /* Más ancho en móviles */
    padding: 12px; /* Reduce el padding en móviles */
    font-size: 16px; /* Ajusta el tamaño de fuente */
  }
}

/* Styles for Escuela Images Gallery */
.escuela-images-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tres columnas iguales */
  gap: 0; /* Sin espacios entre las imágenes */
  justify-items: stretch; /* Asegura que las imágenes ocupen todo el espacio del contenedor */
  align-items: stretch;
  max-width: 1200px;
  margin: 0 auto;
}

.escuela-images-container {
  width: 100%; /* Ocupa el 100% del espacio */
  height: 100%; /* Mantiene la altura */
}

.escuela-images-image {
  width: 80%;
  height: 80%;
  object-fit: cover; /* Asegura que la imagen cubra completamente su espacio sin distorsión */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.escuela-images-image:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Responsiveness for Escuela Images */
@media (max-width: 992px) {
  .escuela-images-gallery {
    grid-template-columns: repeat(2, 1fr); /* Dos columnas en pantallas medianas */
  }
}

@media (max-width: 576px) {
  .escuela-images-gallery {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }
}
