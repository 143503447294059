/* Noticias.css */

.gallery-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.card {
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  margin: 1px;
}

.card-image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: height 0.3s ease;
}

.card.open .card-image {
  height: 100%;
}

.card-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.card-title {
  width: 50%;
  font-size: 10px;
  margin-top: 50%;
  margin-bottom: 0.5em;
  text-align: left;
  justify-content: left;
  margin: 0 auto;
}

.card-description {
  font-size: 50px;
  color: white;
  text-align: center;
  display: none;
  margin-bottom: 1em;
  margin-left: 10px;
  margin-right: 70px;
}

.card-button {
  padding: 12px 24px;
  font-size: 16px;
  color: #0e2051;
  background-color: #fff; /* Color de fondo azul */
  border: none;
  margin-top: 15%;
  border-radius: 30px; /* Borde redondeado */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombra suave */
}

.card.open .card-description,
.card:hover .card-description {
  display: block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-noticias {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 80%;
  text-align: center;
  max-height: 80%;
  overflow-y: auto; /* Habilita el scroll vertical */
}

.modal-noticias h2 {
  font-size: 40px;
  line-height: 1.6;
  margin-bottom: 15px;
  text-align: center;
  border-bottom: 2px solid #0e2051; /* Línea roja debajo del h2 */
  padding-bottom: 10px; /* Espacio entre el texto y la línea */
}

.modal-noticias p {
  font-size: 20px;
  line-height: 1.6; /* Puedes ajustar el espaciado entre líneas */
  margin-bottom: 15px;
  text-align: left;
}

.close-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 20px;
}

.close-button:hover {
  background-color: #0056b3;
}

.modal-date {
  font-size: 25px;
  color: #888;
  margin-bottom: 10px;
  text-align: center;
}

.card-date {
  margin-top: 8%;
  font-size: 20px;
  color: #ddd;
  margin-bottom: 10px;
}

/* Media Queries para Responsividad */
@media (max-width: 768px) {
  .gallery-container {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin-bottom: 10px; /* Añade un margen entre las tarjetas */
  }

  .card-title {
    font-size: 14px; /* Aumenta el tamaño de fuente en móviles */
    margin-top: 5%;
  }

  .card-description {
    font-size: 24px; /* Reduce el tamaño de la descripción */
    margin-left: 5px;
    margin-right: 5px; /* Ajusta el margen lateral */
  }

  .card-button {
    padding: 10px 20px; /* Ajusta el tamaño del botón */
    font-size: 18px; /* Tamaño de fuente más pequeño en móviles */
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .modal-noticias {
    width: 95%; /* Mayor ancho en móviles */
    padding: 10px; /* Menos padding en móviles */
  }

  .modal-noticias h2 {
    font-size: 30px; /* Disminuir el tamaño del h2 */
  }

  .modal-noticias p {
    font-size: 18px; /* Disminuir el tamaño de párrafo */
  }

  .close-button {
    padding: 8px 16px; /* Ajusta el tamaño del botón de cerrar */
    font-size: 16px; /* Tamaño de fuente más pequeño para el botón de cerrar */
  }

  .modal-date {
    font-size: 16px; /* Tamaño de fuente más pequeño para la fecha */
  }

  .card-date {
    font-size: 16px; /* Disminuir el tamaño de la fecha en tarjetas */
    text-align: left;
    margin-left: 20%;
  }
}
