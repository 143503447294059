/* EquipoHeader.css */
.equipo-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 1000; /* Aumenta el z-index aquí */
}

.equipo-header.at-top {
  background-color: rgba(255, 255, 255, 0);
}

.equipo-header.scrolled {
  background-color: #ffffff; /* Color de fondo negro cuando se hace scroll */
}

.header-logo img {
  height: 50px;
  transition: height 0.3s ease;
  margin-left: 60px;
}

.header-logo img.large-logo {
  height: 100px; /* Tamaño del logo cuando está en la parte superior */
}

.header-center {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.header-section {
  margin: 0 20px;
  cursor: pointer;
  color: #ffffff; /* Cambiar el color del texto */
  font-weight: bold;
  font-style: italic;
  position: relative;
  font-size: 20px;
  transition: color 0.3s ease;
}

.equipo-header.scrolled .header-section {
  color: #0073e6; /* Color del texto cuando se hace scroll */
}

.header-section.active::after {
  content: "";
  position: absolute;
  bottom: -5px; /* Ajusta la posición del subrayado */
  left: 0;
  right: 0;
  height: 3px; /* Ajusta el grosor del subrayado */
  background-color: #0073e6; /* Color del subrayado */
}

.header-socials {
  display: flex;
  gap: 10px;
}

.header-socials a {
  color: #ffffff; /* Color inicial de los iconos */
  transition: color 0.3s ease;
}

.equipo-header.scrolled .header-socials a {
  color: #0073e6; /* Color de los iconos cuando se hace scroll */
}

.header-socials a:hover {
  color: #0073e6; /* Color al pasar el cursor sobre los iconos */
}

/* Media queries para el diseño responsive */
@media (max-width: 1024px) {
  .header-section {
    font-size: 18px;
    margin: 0 15px;
  }
  .header-logo img {
    height: 40px;
  }
  .header-logo img.large-logo {
    height: 80px;
  }
}

@media (max-width: 768px) {
  .header-section {
    font-size: 16px;
    margin: 0 10px;
  }
  .header-logo img {
    height: 30px;
  }
  .header-logo img.large-logo {
    height: 60px;
  }
}

@media (max-width: 480px) {
  .header-section {
    font-size: 14px;
    margin: 0 5px;
  }
  .header-logo img {
    height: 25px;
  }
  .header-logo img.large-logo {
    height: 50px;
  }

  .header-socials {
    display: none;
  }

  .position-section h2 {
    font-size: 80px;
    margin-bottom: 70px;
  }
}
