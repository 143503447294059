.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header.at-top {
    background-color: rgba(255, 255, 255, 0);
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 50px;
    transition: height 0.3s ease;
    margin-right: 10px; /* Ajusta según sea necesario */
}

.logo img.large-logo {
    height: 100px; /* Tamaño del logo cuando está en la parte superior */
}

nav {
    display: flex;
    flex-direction: row;
}

nav ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
}

nav ul li {
    margin-right: 70px;
    position: relative;
}

nav ul li a {
    text-decoration: none;
    color: #ffffff;
    padding-bottom: 5px;
    font-weight: bold;
    font-style: italic;
    position: relative;
    display: inline-block;
    overflow: hidden;
    transition: color 0.3s ease;
}

nav ul li a.scrolled {
    color: #0073e6; /* Cambia el color del texto cuando se hace scroll */
}

nav ul li a.scrolled::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #0073e6;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

nav ul li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #fefefe;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

nav ul li a:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

.social-media {
    display: none; /* Ocultar redes sociales inicialmente */
    gap: 10px;
    background-color: #34aef9;
    padding: 10px;
    border-radius: 0 0 0 20px;
    position: absolute;
    bottom: -45px;
    right: 0;
    align-items: center;
}

.header:not(.at-top) .social-media {
    display: flex; /* Mostrar redes sociales cuando se hace scroll */
}

.social-media a {
    color: #ffffff;
    font-size: 20px;
    transition: color 0.3s ease;
}

.social-media a:hover {
    color: #0073e6;
}

.menu-toggle {
    display: none;
    font-size: 34px;
    cursor: pointer;
}

.menu-toggle.white-menu {
    color: #ffffff;
}

.menu-toggle.black-menu {
    color: #000000;
}

@media (max-width: 768px) {
   
    .menu-toggle {
        display: block;
    }

    nav {
        position: fixed;
        top: 120px; /* Mantener el menú justo debajo del header */
        left: 0;
        width: 100%;
        height: 43vh; /* Mantener la altura del menú */
        background-color: #fefefe; /* Fondo blanco para el menú */
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        padding: 20px;
        transform: translateX(-100%);
        transition: transform 0.4s ease-in-out; /* Transición suave */
        z-index: 999;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para darle profundidad */
    }

    nav.open {
        transform: translateX(0); /* Mostrar el menú deslizándose desde la izquierda */
    }

    nav ul {
        flex-direction: column;
        width: 100%;
    }

    nav ul li {
        width: 100%;
        margin-right: 15px;
        margin-bottom: 20px;
    }

    nav ul li a {
        color: #333; /* Color del texto más oscuro para mejor contraste */
        font-size: 18px; /* Mantener el tamaño de la fuente */
        padding: 10px 0;
        width: 100%;
        transition: background-color 0.3s ease, color 0.3s ease;
        border-bottom: 1px solid #ddd; /* Línea debajo de cada enlace para separación */
    }

    nav ul li a:hover {
        background-color: #f0f0f0; /* Fondo gris claro al pasar el cursor */
        color: #0073e6; /* Texto azul al pasar el cursor */
    }

    nav ul li a:active {
        background-color: #e0e0e0; /* Fondo un poco más oscuro cuando el enlace está activo */
    }
}




