.third-sponsors-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 60px; /* Incrementado para más espacio entre los patrocinadores */
  padding: 10px;
  margin: 20px;
  justify-content: center;
}

.third-sponsor-logo {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  max-height: 600px;
  flex-shrink: 0;
  margin-left: 80px;
  object-fit: contain; /* Mantiene la imagen ajustada sin cortarla */
}

@media (max-width: 768px) {
  .third-sponsors-container {
    justify-content: center; /* Centra los logos en la pantalla */
    overflow-x: hidden; /* Evita desbordamiento en pantallas pequeñas */
    margin-right: 0; /* Ajusta el margen para evitar cortes */
  }

  .third-sponsor-logo {
    max-width: 90%; /* Se asegura de que la imagen se ajuste mejor a la pantalla */
    max-height: 300px;
    margin-left: 20px; /* Reduce el margen izquierdo en pantallas pequeñas */
    margin-right: 20px; /* Agrega margen derecho para asegurar que no se corta */
  }
}
