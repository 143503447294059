/* Galeria.css */

.gallery-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 400px; /* Altura predeterminada */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.card {
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  margin: 1px;
}

.card-image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.card-image {
  width: 100%;
  height: 570px; /* Altura predeterminada */
  object-fit: cover;
  transition: height 0.3s ease;
}

.card.open .card-image {
  height: 570px; /* Altura desplegada */
}

.card-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.card-title {
  width: 70%;
  font-size: 30px;
  margin-top: 5%;
  margin-bottom: 0.5em;
  text-align: left;
  justify-content: left;
}

.card-description {
  font-size: 30px;
  color: white;
  text-align: center;
  display: none; /* Inicialmente oculto */
  margin-bottom: 1em;
  margin-left: 70px;
  margin-right: 70px;
}

.card.open .card-description,
.card:hover .card-description {
  display: block; /* Mostrar al abrir o hacer hover */
}

/* Media Queries para Responsividad */
@media (max-width: 768px) {
  .gallery-container {
    flex-direction: column; /* Cambiar a columna en móviles */
    height: auto; /* Ajustar altura a auto */
  }

  .card {
    width: 100%; /* Ancho completo en móviles */
    margin-bottom: 10px; /* Espacio entre las tarjetas */
  }

  .card-image {
    height: 200px; /* Altura cerrada por defecto en móviles */
  }

  .card.open .card-image {
    height: 570px; /* Altura desplegada en móviles */
  }

  .card-title {
    font-size: 22px; /* Tamaño de fuente ajustado en móviles */
    margin-top: 10%; /* Ajustar margen superior */
  }

  .card-description {
    font-size: 20px; /* Tamaño de fuente ajustado para la descripción */
    margin-left: 10px; /* Ajustar márgenes laterales */
    margin-right: 10px; /* Ajustar márgenes laterales */
  }

  .card:hover .card-description {
    display: none; /* No mostrar descripción al hover en móviles */
  }
}
