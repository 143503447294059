.patrocinador1-background-image {
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100vh; /* Ajusta la altura según sea necesario */
}

.patrocinador1-center-box {
  background-color: rgba(8, 140, 196, 0.8); /* Color dorado semitransparente */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 800px;
  height: 500px;
}

.patrocinador1-center-box-link {
  text-decoration: none; /* Quita el subrayado del enlace */
  color: inherit; /* Mantiene el color del texto */
}

.patrocinador1-image-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.patrocinador1-sponsor-logo {
  width: 350px; /* Ajusta el tamaño de las imágenes según sea necesario */
  height: auto;
}

.patrocinador1-team-logo {
  width: 200px; /* Ajusta el tamaño de las imágenes según sea necesario */
  height: auto;
}

.patrocinador1-separator {
  width: 2px; /* Ancho de la línea de separación */
  background-color: white;
  margin: 0 20px; /* Espaciado entre las imágenes y la línea */
  height: 100px; /* Ajusta la altura según sea necesario */
}

.patrocinador1-sponsor-text {
  color: rgb(255, 255, 255); /* Color del texto */
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
}

/* Responsividad para tablets */
@media (max-width: 768px) {
  .patrocinador1-center-box {
    width: 90%;
    height: auto;
    padding: 10px;
  }

  .patrocinador1-sponsor-logo {
    width: 250px; /* Ajusta el tamaño de las imágenes según sea necesario */
  }

  .patrocinador1-team-logo {
    width: 150px; /* Ajusta el tamaño de las imágenes según sea necesario */
  }

  .patrocinador1-separator {
    margin: 0 10px; /* Espaciado entre las imágenes y la línea */
    height: 75px; /* Ajusta la altura según sea necesario */
  }

  .patrocinador1-sponsor-text {
    font-size: 18px;
  }
}

/* Responsividad para móviles */
@media (max-width: 480px) {
  .patrocinador1-center-box {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .patrocinador1-image-container {
    flex-direction: column;
  }

  .patrocinador1-separator {
    width: 100%; /* Ancho de la línea de separación */
    height: 2px; /* Ancho de la línea de separación */
    margin: 10px 0; /* Espaciado entre las imágenes y la línea */
  }

  .patrocinador1-sponsor-logo {
    width: 70%;
  }

  .patrocinador1-team-logo {
    width: 40%; /* Ajusta el tamaño de las imágenes según sea necesario */
  }

  .patrocinador1-sponsor-text {
    font-size: 16px;
  }
}
