.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0073e6;
    color: rgb(255, 255, 255);
    padding: 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-style: italic;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 20px;
    gap: 10px;
}

.footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Añade espacio entre las dos imágenes */
}

.footer-logo img {
    max-height: 110px;
    margin-bottom: 0; /* Asegúrate de que no haya margen inferior */
    flex-direction: row; /* Asegura que las imágenes se alineen en fila */
}


.social-logos {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.social-icon {
    color: white;
    font-size: 24px;
    transition: color 0.3s;
}

.social-icon:hover {
    color: #ccc;
}

.footer-divider {
    border-left: 2px solid white;
    height: 100px;
    margin: 0 20px;
}

.footer-heading {
    text-decoration: underline;
    margin-bottom: 10px;
    font-size: 20px;
}

.footer-left p {
    font-size: 14px; /* Adjust the font size as needed */
}

.footer-left p.footer-heading {
    font-size: 16px; /* Optionally, make the heading slightly larger */
}


.footer-left p, 
.footer-right ul {
    margin: 5px 0;
}

.footer-left p a {
    color: white;
    text-decoration: none;
}

.footer-left p a:hover {
    text-decoration: underline;
}

.footer-right ul {
    list-style: none;
    padding: 0;
}

.footer-right ul li {
    margin: 5px 0;
}

.footer-right ul li a {
    color: white; /* Change font color to white */
    text-decoration: none; /* Remove underline */
}

.footer-right ul li a:hover {
    text-decoration: underline; /* Optional: Add underline on hover if desired */
}

.noos-solutions-link {
    text-decoration: none; /* Remove default underline */
    border-bottom: 1px solid white; /* Add a white underline */
    color: inherit; /* Inherit text color */
  }
  
  .noos-solutions-link:hover {
    color: inherit; /* Ensure the color stays the same on hover */
    border-bottom: 1px solid white; /* Ensure underline is still white on hover */
  }
  

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1050;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #f0f0f0;
    color: #000000;
    margin: auto;
    padding: 20px;
    border: 1px solid #0073e6;
    width: 80%;
    max-width: 900px;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 8px;
    position: relative;
}

.modal-content h2 {
    font-weight: bold;
    background-color: #0073e6;
    color: #ffffff;
    padding: 10px;
    border-radius: 8px 8px 0 0;
}

.modal-content p {
    margin-top: 10px;
    font-size: 14px;
}

.close {
    color: #000000;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 10px;
    }

    .footer-divider {
        border-left: none;
        border-top: 2px solid white;
        height: auto;
        width: 80%;
        margin: 10px 0;
    }

    .footer-section {
        margin: 10px 0;
    }

    .footer-heading {
        font-size: 18px;
    }

    .social-icon {
        font-size: 20px;
    }

    .modal-content {
        width: 90%;
        padding: 10px;
    }

    .modal-content h2 {
        font-size: 18px;
        padding: 5px;
    }

    .close {
        font-size: 24px;
    }
}
