.container {
  width: 100%;
  padding: 10px;
  background-color: rgb(223, 223, 238);
}

.container h2 {
  font-size: 40px;
  text-align: left;
  margin-top: 5px;
  margin-left: 20px;
  font-weight: bold;
  font-family: 'Bebas Neue', sans-serif !important;
  color: #1c2237;
}

.basketball-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 40px;
}

.section {
  background-color: #1c2237;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 450px;
  height: 300px;
  position: relative;
  padding: 20px;
}

.section::before {
  content: '';
  background-image: url('../../assets/fondoplantilla.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
}

.content {
  position: relative;
  z-index: 1;
}

h3 {
  margin: 0;
}

/* Media query for mobile devices */
/* Media query for mobile devices */
@media (max-width: 768px) {
  .basketball-sections {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .section {
    width: 48%;
    height: auto;
  }
}

/* Media query for tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .basketball-sections {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .section {
    width: 48%;
    height: auto;
  }
}

/* For larger screens, it returns to normal */
@media (min-width: 1025px) {
  .basketball-sections {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .section {
    width: 450px;
    height: 300px;
  }
}

