*,
*:after,
*:before {
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
  background-color: #fff !important;
  padding: 0;
  margin: 0;
}
.slider-wrap {
  position: relative;
}
.image-slider {
  height: 100vh;
  overflow: hidden;
}
.img-wrap {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
}
.img-wrap img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

/* Text Styles */
.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 2;
  font-family: "Montserrat", sans-serif;
}

.slider-text h2 {
  font-size: 16px;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 400;
  color: #ffffffcc; /* Slight transparency for subtitle */
}

.slider-text h1 {
  margin: 10px 0;
  font-size: 48px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.slider-stars {
  margin-top: 15px;
}

.slider-stars span {
  font-size: 24px;
  color: #fff;
  margin: 0 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Arrow Styles */
.slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  background-color: transparent;
  border-radius: 60px;
  border: 2px solid #fff;
  cursor: pointer;
  outline: none;
  z-index: 2;
  display: none;
}

.slick-arrow.slick-prev {
  left: 20px;
}

.slick-arrow.slick-next {
  right: 20px;
}

.slick-arrow:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  display: none;
}

.slick-arrow.slick-prev:before {
  transform: rotate(-45deg) translate(2px, 2px);
}

.slick-arrow.slick-next:before {
  transform: rotate(135deg) translate(2px, 2px);
}

/* Progress Bar */
.slider-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}

.slider-progress span {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 0px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0s linear;
}

.slider-progress span.active {
  width: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .slick-arrow {
    display: none;
  }

  .slider-text {
    top: 50%; /* Cambia la posición superior al 50% para centrar verticalmente */
    left: 50%; /* Asegúrate de que también esté centrado horizontalmente */
    transform: translate(-50%, -50%); /* Ajusta la posición para centrarlo completamente */
  }

  .slider-text h1 {
    font-size: 32px; /* Ajusta el tamaño de la fuente si es necesario */
  }

  .slider-stars span {
    font-size: 18px; /* Ajusta el tamaño de la fuente para las estrellas */
  }
}

