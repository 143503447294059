.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #57aefa;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: hidden;
  }
  
  .preloader-bounce {
    width: 220px;
    height: 220px;
    animation: bounce 1s infinite;
  }
  
  .shadow {
    width: 200px;
    height: 20px;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: calc(50% + 100px); /* adjust based on the height of the ball */
    border-radius: 50%;
    animation: shadow 1s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
  }
  
  @keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
    }
    50% {
      transform: scaleX(0.7);
    }
  }
  