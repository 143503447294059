.secondary-sponsors-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 60px; /* Incrementado para más espacio entre los patrocinadores */
  padding: 10px;
  margin: 20px;
  justify-content: center;
}

.secondary-sponsor-logo {
  max-width: 120px;
  max-height: 120px;
  flex-shrink: 0;
}

/* Responsividad para tablets */
@media (max-width: 1024px) {
  .secondary-sponsors-container {
    flex-wrap: wrap;
    gap: 40px; /* Ajusta el espacio entre los patrocinadores */
    grid-template-columns: repeat(2, 1fr);
  }

  .secondary-sponsor-logo {
    max-width: 80px;
    max-height: 80px;
  }
}

/* Responsividad para móviles */
@media (max-width: 480px) {
  .secondary-sponsors-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; /* Ajusta el espacio entre los patrocinadores */
    margin-top: 40px !important; /* Agrega margen encima del contenedor */
  }

  .secondary-sponsor-logo {
    max-width: 60px;
    max-height: 60px;
  }
}