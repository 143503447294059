.el-club-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #f7f9fc;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  max-width: 1300px;
  margin: 0 auto;
}

.el-club-info {
  text-align: center;
  margin-bottom: 40px;
}

.el-club-info h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #2c3e50;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.el-club-info p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #34495e;
}

.el-club-info h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 15px;
}

.directiva-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
}

.directiva-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 250px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.directiva-card h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #007bff;
}

.directiva-card p {
  font-size: 18px;
  color: #2c3e50;
}

.el-club-palmares {
  width: 100%;
  text-align: center;
}

.el-club-palmares h3 {
  font-size: 26px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.palmares-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.palmares-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 16px;
}

.palmares-controls button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.palmares-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.year-display {
  font-size: 22px;
  font-weight: bold;
  color: #34495e;
}

.palmares-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 40px;
}

.palmares-card {
  background-color: #1c2237;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 300px;
  position: relative;
  padding: 20px;
  text-align: center;
}

.palmares-card::before {
  content: '';
  background-image: url('../../assets/fondopalmares.jpg');
  background-size: cover; /* Cambia de contain a cover */
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
}


.content {
  position: relative;
  z-index: 1;
  text-align: center;
}

h4 {
  margin: 0;
  font-size: 34px;
  font-weight: bold;
}

p {
  margin: 10px 0 0;
  font-size: 28px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .palmares-cards {
    flex-direction: column;
    align-items: center;
  }

  .palmares-card {
    width: 100%;
    height: auto;
  }
}

/* Media query for tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .palmares-cards {
    flex-direction: column;
    align-items: center;
  }

  .palmares-card {
    width: 100%;
    height: auto;
  }
}

/* For larger screens, it returns to normal */
@media (min-width: 1025px) {
  .palmares-cards {
    flex-direction: row;
    justify-content: center;
  }

  .palmares-card {
    width: 450px;
    height: 300px;
  }
}
