/* Instalaciones.css */
.installations-container {
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
  background: #f9f9f9;
  font-family: "Arial", sans-serif;
}

.installations-header {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  color: #333;
}

.installations-header h2 {
  font-size: 70px;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
  font-family: "Bebas Neue", sans-serif !important;
  color: #2f3e93;
  margin-bottom: -20px;
}

.installations-header p {
  font-size: 20px;
  color: #000000;
  text-align: center;
  font-weight: bold;
}

.installations-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.image-container {
  width: 100%;
  position: relative;
  height: 600px; /* Ajusta la altura según sea necesario */
  overflow: hidden;
  border-radius: 10px;
}

.installation-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-container {
  position: absolute;
  top: 300px;
  right: 20px; /* Ajusta la posición según sea necesario */
  width: 25%; /* Ajusta el ancho según sea necesario */
  height: 300px; /* Ajusta la altura según sea necesario */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .installations-header h2 {
    font-size: 50px;
  }

  .installations-header p {
    font-size: 18px;
  }

  .installations-content {
    flex-direction: column;
    align-items: center;
  }

  .map-container {
    position: relative;
    top: 20px;
    right: 0;
    width: 90%;
    height: 200px;
    margin-top: 20px;
  }

  .image-container {
    width: 90%;
    height: 300px;
  }
}

@media (max-width: 480px) {
  .installations-header h2 {
    font-size: 35px;
    margin-bottom: 5px;
  }

  .installations-header p {
    font-size: 16px;
  }

  .installations-content {
    flex-direction: column;
    align-items: center;
  }

  .map-container {
    position: relative;
    top: 20px;
    right: 0;
    width: 100%;
    height: 150px;
    margin-top: 20px;
  }

  .image-container {
    width: 100%;
    height: 200px;
  }

  .image-container img {
    width: 100%;
  }
}
