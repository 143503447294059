@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.equipo-container {
  position: relative;
  z-index: 1;
}

.equipo-background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/fondoplantilla.jpg');
  background-size: cover;
  background-position: center;
  filter: blur(6px);
  z-index: -1;
}

.player-card {
  width: 100%;
  max-width: 450px;
  border: 2px solid #dddddd;
  border-radius: 35px;
  overflow: hidden;
  background-color: #225983;
  text-align: center;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.player-card:hover {
  transform: scale(1.05);
}

.player-number {
  position: absolute;
  bottom: 36px;
  right: 10px;
  font-size: 60px;
  font-weight: bold;
  color: #f7f7f7;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Bebas Neue", sans-serif !important;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.player-image {
  width: 100%;
  height: 450px; /* Fija la altura de las imágenes */
  object-fit: cover; /* Asegura que la imagen cubra todo el contenedor sin deformarse */
  transition: opacity 0.5s ease-in-out;
}


.player-name {
  position: absolute;
  bottom: 10px; /* Cambiar de top a bottom */
  left: 10px; /* Cambiar de right a left */
  font-size: 50px;
  font-family: "Bebas Neue", sans-serif !important;
  color: white;
  padding: 5px;
  border-radius: 5px;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.player-position {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 38px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 10px; /* Cambiar de bottom a top */
  right: 10px; /* Cambiar de left a right */
  font-family: "Bebas Neue", sans-serif !important;
  color: white;
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}


.name-part {
  margin: 2px 0;
}

.team-roster {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Cuatro columnas de igual tamaño en ordenadores */
  gap: 20px; /* Espacio entre las tarjetas */
  margin-top: 20px; /* Ajuste de margen superior para espaciar las secciones */
  justify-items: center; /* Centra las tarjetas dentro de las columnas */
}

.technical-staff-section {
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 60px;
  color: white;
  margin: 20px 0;
  text-align: center; /* Center the position title */
}





@media (max-width: 1024px) {
  .team-roster {
    grid-template-columns: repeat(3, 1fr); /* Mantener tres columnas en pantallas medianas */
  }

  .player-name {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 35px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    top: 10px; /* Cambiar de bottom a top */
    right: 10px;
    font-family: "Bebas Neue", sans-serif !important;
    color: white;
  }
}

@media (max-width: 768px) {
  .team-roster {
    grid-template-columns: repeat(3, 1fr); /* Mantener tres columnas en pantallas pequeñas */
  }

  .player-name {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    top: 10px; /* Cambiar de bottom a top */
    right: 10px;
    font-family: "Bebas Neue", sans-serif !important;
    color: white;
  }


}

@media (max-width: 480px) {
  .team-roster {
    grid-template-columns: 1fr; /* Una sola columna en pantallas muy pequeñas */
  }
}
